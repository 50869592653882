var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('loading-overlay',{attrs:{"active":_vm.apiStateFormLoading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.listOfGroupsAndImplements')))])]),_c('CCardBody',[_c('modal-group',{attrs:{"groupData":_vm.groupSelected,"titleModalGroup":_vm.titleModalGroup}}),_c('modal-implement',{attrs:{"implementData":_vm.implementSelected,"titleModalImplement":_vm.titleModalImplement}}),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('CustomTabs',{attrs:{"activeTab":0},on:{"update:activeTab":_vm.handleTab}},[_c('CustomTab',{attrs:{"title":_vm.$t('label.groups')}},[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                        content: _vm.$t('label.implementsGroup'),
                                        placement: 'top-end',
                                        }),expression:"{\n                                        content: $t('label.implementsGroup'),\n                                        placement: 'top-end',\n                                        }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.showModalCreateGroup}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"loading":_vm.apiStateLoading,"size":"lg","fields":_vm.fieldsGroup,"items":_vm.formatedGpoImplementoItems,"table-filter":_vm.tableText.tableFilterText,"column-filter":"","items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"noItemsView":_vm.tableText.noItemsViewText,"pagination":"","hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"GpoImplementId",fn:function({ index}){return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.status)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")]):_vm._e(),(!item.status)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")]):_vm._e()],1)]}},{key:"acciones",fn:function({item,index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                            content: _vm.$t('label.editImplementGroup'),
                                                            placement: 'top-end'
                                                        }),expression:"{\n                                                            content: $t('label.editImplementGroup'),\n                                                            placement: 'top-end'\n                                                        }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.showModalEditGroup(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('CustomTab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('img',{staticClass:"mr-1",attrs:{"width":"100%","height":"100%","src":"/img/iconos/Tab-Implements.png","alt":"Card image cap"}}),_vm._v(" "+_vm._s(_vm.$t('label.implements'))+" ")]},proxy:true}])},[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                        content: _vm.$t('label.implement'),
                                        placement: 'top-end',
                                        }),expression:"{\n                                        content: $t('label.implement'),\n                                        placement: 'top-end',\n                                        }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.showModalCreateImplement}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedImplementoItems,"fields":_vm.fieldsImplement,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":"","items-per-page":_vm.tableText.itemsPerPage,"pagination":"","column-filter":"","hover":"","loading":_vm.apiStateLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"ImplementId",fn:function({ index}){return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.status)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")]):_vm._e(),(!item.status)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")]):_vm._e()],1)]}},{key:"acciones",fn:function({item,index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                            content: _vm.$t('label.edit') +' ' + _vm.$t('label.Implement') ,
                                                            placement: 'top-end'
                                                        }),expression:"{\n                                                            content: $t('label.edit') +' ' + $t('label.Implement') ,\n                                                            placement: 'top-end'\n                                                        }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.showModalEditImplement(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
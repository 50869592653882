<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader class="text-center bg-dark text-white">
                    <b>{{$t('label.listOfGroupsAndImplements')}}</b>
                </CCardHeader>
                <CCardBody>
                    <modal-group :groupData="groupSelected" :titleModalGroup="titleModalGroup"/>
                    <modal-implement :implementData="implementSelected" :titleModalImplement="titleModalImplement"/>
                    <CRow class="mt-2">
                        <CCol sm="12">
                            <CustomTabs :activeTab="0" @update:activeTab="handleTab">
                                <CustomTab :title="$t('label.groups')">
                                    <CRow>
                                        <CCol sm="12" class="d-flex justify-content-end py-2">
                                        <CButton
                                            color="add"
                                            class="d-flex align-items-center"
                                            @click="showModalCreateGroup"
                                            v-c-tooltip="{
                                            content: $t('label.implementsGroup'),
                                            placement: 'top-end',
                                            }"
                                        >
                                            <CIcon name="cil-playlist-add" />{{$t('label.nuevo')}}
                                        </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <dataTableExtended
                                                :loading="apiStateLoading"
                                                class="align-center-row-datatable"
                                                size="lg"
                                                :fields="fieldsGroup"
                                                :items="formatedGpoImplementoItems"
                                                :table-filter="tableText.tableFilterText"
                                                column-filter
                                                :items-per-page-select="tableText.itemsPerPageText"
                                                :items-per-page="tableText.itemsPerPage"
                                                :noItemsView="tableText.noItemsViewText"
                                                pagination
                                                hover 
                                                sorter 
                                            >
                                                <template #loading>
                                                    <loading/>
                                                </template>
                                    
                                                <template #GpoImplementId="{ index}">
                                                    <td class="text-left">
                                                        {{index+1}}
                                                    </td>
                                                </template>
                                                <template #status="{item}">
                                                    <td class="text-center">
                                                        <CBadge v-if="item.status" color="success">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                        <CBadge v-if="!item.status" color="danger">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                    </td>
                                                </template>
                                                <template #acciones="{item,index}">
                                                    <td class="text-center">
                                                        <CButton
                                                            color="edit"
                                                            size="sm"
                                                            @click="showModalEditGroup(item, index)"
                                                            v-c-tooltip="{
                                                                content: $t('label.editImplementGroup'),
                                                                placement: 'top-end'
                                                            }"                                                    
                                                        >
                                                            <CIcon name="pencil"/>
                                                        </CButton>
                                                    </td>
                                                </template>
                                            </dataTableExtended>
                                        </CCol>
                                    </CRow>
                                </CustomTab>
                                <CustomTab >
                                    <template #title>
                                        <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Implements.png" alt="Card image cap">
                                        {{$t('label.implements')}}                
                                    </template>
                                    <CRow>
                                        <CCol sm="12" class="d-flex justify-content-end py-2">
                                        <CButton
                                            color="add"
                                            class="d-flex align-items-center"
                                            @click="showModalCreateImplement"
                                            v-c-tooltip="{
                                            content: $t('label.implement'),
                                            placement: 'top-end',
                                            }"
                                        >
                                            <CIcon name="cil-playlist-add" />{{$t('label.nuevo')}}
                                        </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <dataTableExtended
                                                class="align-center-row-datatable"
                                                :items="formatedImplementoItems"
                                                :fields="fieldsImplement"
                                                :table-filter="tableText.tableFilterText"
                                                :items-per-page-select="tableText.itemsPerPageText"
                                                :noItemsView="tableText.noItemsViewText"
                                                sorter                                            
                                                :items-per-page="tableText.itemsPerPage"
                                                pagination
                                                column-filter
                                                hover
                                                :loading="apiStateLoading"

                                            >
                                                <template #loading>
                                                    <loading/>
                                                </template>
                                                <template #ImplementId="{ index}">
                                                    <td class="text-left">
                                                        {{index+1}}
                                                    </td>
                                                </template>
                                                <template #status="{item}">
                                                    <td class="text-center">
                                                        <CBadge v-if="item.status" color="success">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                        <CBadge v-if="!item.status" color="danger">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                    </td>
                                                </template>
                                                <template #acciones="{item,index}">
                                                    <td class="text-center">
                                                        <CButton
                                                            color="edit"
                                                            size="sm"
                                                            @click="showModalEditImplement(item, index)"
                                                            v-c-tooltip="{
                                                                content: $t('label.edit') +' ' + $t('label.Implement') ,
                                                                placement: 'top-end'
                                                            }"
                                                            
                                                        >
                                                            <CIcon name="pencil"/>
                                                        </CButton>
                                                    </td>
                                                </template>
                                            </dataTableExtended>
                                        </CCol>
                                    </CRow>
                                </CustomTab>
                            </CustomTabs>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>
<style>
</style>
<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import modalGroup from './modal-group.vue';
    import modalImplement from './modal-implement.vue';
    import general from '@/_mixins/general';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';

    //data
    function data() {
        return {
            activePage: 1,
            activeTab: 0,
            selectedTab:"tab1",
            originalActGpoImplemento:'',
            originalActImplemento:'',
            loading:false,
            groupSelected: {},
            titleModalGroup: '',
            implementSelected: {},
            titleModalImplement: '',
        }
    }

    //mounted
    function mounted() {
        this.$store.commit('implemento/mutationModalGroup', false);
        this.$store.commit('implemento/mutationModalImplement', false);
        this.$store.dispatch('implemento/getGpoImplementolist', { filtro : 'ALL', type : 1});
    }

    //methods
    function handleTab(tab) {
        this.activeTab = tab;
    }
    function isActiveTab(current) {
        return this.activeTab == current;
    }

    function idIndentification(id){
        return id === 0;
    }

    function showModalCreateGroup(){
        this.groupSelected = {};
        this.titleModalGroup = this.$t('label.nuevo')+' '+this.$t('label.implementsGroup');
        this.$store.state.implemento.idGpoImplemento = 0;
        this.$store.commit('implemento/mutationModalGroup', true);
    }

    function showModalEditGroup(item, index){
        this.groupSelected = item;
        this.titleModalGroup = this.$t('label.edit')+' '+this.$t('label.implementsGroup')+': '+this.groupSelected.GpoImplementName;
        this.$store.state.implemento.idGpoImplemento = item.GpoImplementId;
        this.$store.commit('implemento/mutationModalGroup', true);
    }
  
    function showModalCreateImplement(){
        this.implementSelected = {};
        this.$store.state.implemento.idGpoImplemento = '';
        this.titleModalImplement = this.$t('label.nuevo')+' '+this.$t('label.implement');
        this.$store.state.implemento.idImplemento = 0;
        this.$store.commit('implemento/mutationModalImplement', true);
    }

    async function showModalEditImplement(item, index){
        this.implementSelected = item;
        this.titleModalImplement = this.$t('label.edit')+' '+this.$t('label.implement')+': '+this.implementSelected.ImplementName;
        this.$store.state.implemento.idImplemento = item.ImplementId;
        this.$store.state.implemento.implementRoute = item.ImplementRoute;
        this.$store.commit('implemento/mutationModalImplement', true);
    }
    
    function formatedGpoImplementoItems() {
        const computedItems = this.$store.getters["implemento/myDataGpoImplementotable"];
        let _lang = this.$i18n.locale;
        return computedItems.filter(item => {
            item.GpoImplementName = _lang=='en' ? item.GpoImplementNameEn : item.GpoImplementNameEs;
            return item
        })
    }
    function formatedImplementoItems() {
        const computedItems = this.$store.getters["implemento/myDataImplementotable"];
        let _lang = this.$i18n.locale;
        return computedItems.filter(item => {
            item.GpoImplementName = _lang=='en' ? item.GpoImplementNameEn : item.GpoImplementNameEs;
            return item
        })
    }

    //computed
    function fieldsGroup(){
        return[
            { key: 'GpoImplementId', label: '#', _classes: 'text-left', _style: 'width:3%; text-align:left', filter: false },
            { key: 'GpoImplementName',label: this.$t('label.implementsGroup'), _classes: 'text-uppercase text-center', _style:'width:45%'},
            { key: 'UltimoUsuario', label: this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:15%'},
            { key: 'FormatedDate', label: this.$t('label.date'), _classes:'text-center', _style:'width:15%'},
            { key: 'status', label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:15%'},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:5%; min-width:45px;', 
                sorter: false, 
                filter: false
            },
        ]
    }
    
    function fieldsImplement() {   
        return[
            { key: 'ImplementId', label: '#', _classes: 'text-left', _style: 'width:3%; text-align:center', filter: false },
            { key: 'ImplementName',label: this.$t('label.Implement'), _classes: 'text-uppercase text-center', _style:'width:14%' },
            { key: 'GpoImplementName',label: this.$t('label.group'), _classes: 'text-uppercase text-center', _style:'width:14%' },
            { key: 'clientName',label: this.$t('label.client'), _classes: 'text-uppercase text-center', _style:'width:20%'},
            { key: 'UltimoUsuario', label: this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:12%'},
            { key: 'FormatedDate', label: this.$t('label.date'), _classes:'text-center', _style:'width:12%'},
            { key: 'status', label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:12%'},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:5%; min-width:45px;',
                sorter: false, 
                filter: false
            }
        ]
    }

    async function getInformationGpoImplemento(){
        this.groupSelected= {};
        const id = this.idGpoImplemento;
        this.$store.state.implemento.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'GpoImplement-by-id',{ GpoImplementId: id }, '' ).then(response => {
            try {
                if(response.status === 200){
                    const Information = response.data.data;
                    if(Information.length !== 0){
                        if(this.formImplemento.IdGpoImplemento === ''){
                            this.formImplemento.IdGpoImplemento = Information[0].GpoImplementId;
                        }
                        this.formGpoImplemento.NbGpoImplemento=Information[0].GpoImplementName;
                        this.originalActGpoImplemento = Information[0].FgActGpoImplement;
                        this.formGpoImplemento.FgActGpoImplemento=Information[0].FgActGpoImplement;
                        this.$store.state.implemento.apiStateForm = ENUM.INIT;
                    }
                }else{
                    this.$store.state.implemento.apiStateForm = ENUM.ERROR;
                    this.$store.commit('implemento/mutationModalGroup', false);
                }
            } catch (error) {
                this.$store.state.implemento.apiStateForm = ENUM.ERROR;
                this.$store.commit('implemento/mutationModalGroup', false);
                this.$store.commit('implemento/messageMutation', err);
            }
        }).catch(err => {
            this.$store.state.implemento.apiStateForm = ENUM.ERROR;
            this.$store.commit('implemento/mutationModalGroup', false);
            this.$store.commit('implemento/messageMutation', err);
        });
    }

    async function getInformationImplemento(){
        const id = this.idImplemento;
        this.$store.state.implemento.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'Implement-by-id',{ ImplementId: id }, '' ).then(response => {
            try {
                if(response.status === 200){
                    const Information = response.data.data;
                    if(Information.length !== 0){
                        //this.$refs.imageInputImplemento.removeImage();
                        if(this.$refs.imageInputImplemento){
                            this.$refs.imageInputImplemento.value=null;
                            this.$refs.imageInputImplemento.file=null;
                        }
                        this.formImplemento.IdGpoImplemento=Information[0].GpoImplementId;
                        this.formImplemento.NbImplemento=Information[0].ImplementName;
                        this.formImplemento.DsImplemento=Information[0].ImplementDs;
                        this.formImplemento.RutaImplemento=Information[0].ImplementRoute;
                        this.originalActImplemento = Information[0].FgActImplement;
                        this.Image_original = Information[0].ImplementRoute;
                        this.formImplemento.FgActImplemento= Information[0].FgActImplement;
                        const icon = Information[0].ImplementRoute;
                        this.formImplemento.Image_url= icon?`${this.$store.getters["connection/getBase"]}${icon.replace('public/', '', 'i')}`:GenerateImgPlaceholder();
                        this.$store.state.implemento.apiStateForm = ENUM.INIT;
                    }
                }else{
                    this.$store.state.implemento.apiStateForm = ENUM.ERROR;
                    this.$store.commit('implemento/mutationModalGroup', false);
                }
            } catch (error) {
                this.$store.commit('implemento/messageMutation', error);
                this.$store.state.implemento.apiStateForm = ENUM.ERROR;
                this.$store.commit('implemento/mutationModalGroup', false);
            }
        }).catch(err => {
            this.$store.commit('implemento/messageMutation', err);
            this.$store.state.implemento.apiStateForm = ENUM.ERROR;
            this.$store.commit('implemento/mutationModalGroup', false);
        });
    }

    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }

    export default{
        name: 'app-implemento',
        data,
        mixins:[
            general
        ],
        components: {
            modalGroup,
            modalImplement,
            CustomTabs,
            CustomTab
        },
        methods: {
            //activarModal,
            //updateModal,
            showModalEditGroup,
            showModalCreateGroup,
            showModalEditImplement,
            showModalCreateImplement,
            idIndentification,
            getInformationGpoImplemento,
            getInformationImplemento,
            isActiveTab,
            handleTab,
        },
        mounted,
        computed: {
            formatedGpoImplementoItems,
            formatedImplementoItems,
            fieldsImplement,
            fieldsGroup,
            ...mapState({
                apiState: state => state.implemento.apiState,
                messageError: state => state.implemento.messageError,

                idGpoImplemento: state=> state.implemento.idGpoImplemento,
                idImplemento: state=> state.implemento.idImplemento,
                //modalUpdated: state=> state.implemento.modalUpdated,
                myDataGpoImplemento: state => state.implemento.myDataGpoImplemento,
                formReset: state=> state.implemento.formReset,
                apiState: state => state.implemento.apiState,
                apiStateForm: state => state.implemento.apiStateForm,
                implementRoute: (state) => state.implemento.implementRoute,

            }),

            apiStateFormLoading,
            apiStateLoading,

        },
        watch:{
        }
    }
</script>
